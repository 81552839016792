<template>
  <div class="content_body ReportEntityTreatDetail" style="padding:0px" v-loading="loading">
    <el-tabs type="border-card">
      <el-tab-pane label="消耗明细">
        <div class="nav_header" style="padding:0px">
          <el-form :inline="true" size="small" :model="searchTreatData" @submit.native.prevent>
            <el-form-item v-if="storeEntityList.length>1" label="开单门店">
              <el-select v-model="searchTreatData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleTreatSearch">
                <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchTreatData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleTreatSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="卡类型">
              <el-select v-model="searchTreatData.TreatCardTypeName" clearable filterable placeholder="请选择卡类型" :default-first-option="true" @change="handleTreatSearch">
                <el-option label="项目卡" value="项目卡"></el-option>
                <el-option label="储值卡" value="储值卡"></el-option>
                <el-option label="时效卡" value="时效卡"></el-option>
                <el-option label="通用次卡" value="通用次卡"></el-option>
                <el-option label="产品卡" value="产品卡"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="searchTreatData.GoodName" clearable @keyup.enter.native="handleTreatSearch" @clear="handleTreatSearch" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchTreatData.CustomerName" clearable @keyup.enter.native="handleTreatSearch" @clear="handleTreatSearch" placeholder="请输入姓名/手机号/编号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="handleTreatSearch">搜索</el-button>
            </el-form-item>
             <el-form-item>
              <el-button v-if="treatDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadTreatExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table size="small" show-summary :summary-method="getTreatDetailListSummaries" :data="treatDetailList" v-loading="treatDetailLoading">
          <el-table-column prop="TreatBillID" label="订单编号"></el-table-column>
          <el-table-column prop="BillDate" label="下单日期"></el-table-column>
          <el-table-column prop="EntityName" label="下单门店"></el-table-column>
          <el-table-column prop="CustomerName" width="150px" label="客户">
            <template slot-scope="scope">
              <div>{{scope.row.CustomerName}} <span v-if="scope.row.CustomerCode">({{scope.row.CustomerCode}})</span> </div>
              <div v-if="scope.row.CustomerPhoneNumber">手机号：{{scope.row.CustomerPhoneNumber}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeName" label="开单人"></el-table-column>
          <el-table-column prop="TreatCardTypeName" label="卡类型"></el-table-column>
          <el-table-column prop="CardName" label="卡名称"></el-table-column>
          <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
          <el-table-column prop="GoodName" label="商品名称"></el-table-column>
          <el-table-column align="right" prop="Price" label="单价">
            <template slot-scope="scope">
              {{scope.row.Price | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="Quantity" label="数量"></el-table-column>
          <el-table-column align="right" prop="IsLargess" label="是否赠送">
            <template slot-scope="scope">
              <div v-if="scope.row.IsLargess">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="right" prop="PreferentialAmount" label="优惠金额">
            <template align="right" slot-scope="scope">
              <div v-if="scope.row.PreferentialAmount<0" class="color_red">{{scope.row.PreferentialAmount | NumFormat}}</div>
              <div v-else-if="scope.row.PreferentialAmount>0" class="color_green">+{{scope.row.PreferentialAmount | NumFormat}}</div>
              <div v-else>0.00</div>
            </template>
          </el-table-column> -->
          <el-table-column align="right" prop="TotalAmount" label="合计金额">
            <template slot-scope="scope">
              {{scope.row.TotalAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TreatPayAmount" label="消耗本金">
            <template slot-scope="scope">
              {{scope.row.TreatPayAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TreatLargessAmount" label="消耗赠额">
            <template slot-scope="scope">
              {{scope.row.TreatLargessAmount | NumFormat}}
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="treatPaginations.total > 0" @current-change="handleTreatDetailPageChange" :current-page.sync="treatPaginations.page" :page-size="treatPaginations.page_size" :layout="treatPaginations.layout" :total="treatPaginations.total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="退消耗明细">
        <div class="nav_header" style="padding:0px">
          <el-form :inline="true" size="small" :model="searchTreatRefundData" @submit.native.prevent>
            <el-form-item v-if="storeEntityList.length>1" label="开单门店">
              <el-select v-model="searchTreatRefundData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleTreatRefundSearch">
                <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchTreatRefundData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleTreatRefundSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="卡类型">
              <el-select v-model="searchTreatRefundData.TreatCardTypeName" clearable filterable placeholder="请选择卡类型" :default-first-option="true" @change="handleTreatRefundSearch">
                <el-option label="项目卡" value="项目卡"></el-option>
                <el-option label="储值卡" value="储值卡"></el-option>
                <el-option label="时效卡" value="时效卡"></el-option>
                <el-option label="通用次卡" value="通用次卡"></el-option>
                <el-option label="产品卡" value="产品卡"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="searchTreatRefundData.GoodName" clearable @keyup.enter.native="handleTreatRefundSearch" @clear="handleTreatRefundSearch" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchTreatRefundData.CustomerName" clearable @keyup.enter.native="handleTreatRefundSearch" @clear="handleTreatRefundSearch" placeholder="请输入姓名/手机号/编号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="handleTreatRefundSearch">搜索</el-button>
            </el-form-item>

             <el-form-item>
              <el-button v-if="refundTreatDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadreatRefundExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table size="small" show-summary :summary-method="getTreatRefundDetailListSummaries" :data="treatRefundDetailList" v-loading="treatRefundDetailLoading">
          <el-table-column prop="TreatBillID" label="订单编号"></el-table-column>
          <el-table-column prop="BillDate" label="下单日期"></el-table-column>
          <el-table-column prop="EntityName" label="下单门店"></el-table-column>
          <el-table-column prop="CustomerName" width="150px" label="客户">
            <template slot-scope="scope">
               <div><span class="marrt_10">{{scope.row.CustomerName}}</span><span v-if="scope.row.CustomerCode">({{scope.row.CustomerCode}})</span></div>
              <div v-if="scope.row.CustomerPhoneNumber">手机号：{{scope.row.CustomerPhoneNumber}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeName" label="开单人"></el-table-column>
          <el-table-column prop="TreatCardTypeName" label="卡类型"></el-table-column>
          <el-table-column prop="CardName" label="卡名称"></el-table-column>
          <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
          <el-table-column prop="GoodName" label="商品名称"></el-table-column>
          <el-table-column align="right" prop="Quantity" label="数量"></el-table-column>
          <el-table-column align="right" prop="IsLargess" label="是否赠送">
            <template slot-scope="scope">
              <div v-if="scope.row.IsLargess">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TotalAmount" label="合计金额">
            <template slot-scope="scope">
              {{scope.row.TotalAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TreatPayAmount" label="消耗本金">
            <template slot-scope="scope">
              {{scope.row.TreatPayAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TreatLargessAmount" label="消耗赠额">
            <template slot-scope="scope">
              {{scope.row.TreatLargessAmount | NumFormat}}
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="treatRefundPaginations.total > 0" @current-change="handleTreatRefundDetailPageChange" :current-page.sync="treatRefundPaginations.page" :page-size="treatRefundPaginations.page_size" :layout="treatRefundPaginations.layout" :total="treatRefundPaginations.total"></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Entity/treatDetailStatement";
import permission from "@/components/js/permission.js";

export default {
  name: "ReportEntityTreatDetail",
  data() {
    return {
      loading: false,
      downloadLoading:false,
      searchTreatData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
        TreatCardTypeName: "",
        GoodName: "",
        CustomerName:null,
        CustomerCode:null,
      },
      searchTreatRefundData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
        TreatCardTypeName: "",
        GoodName: "",
        CustomerName:null,
        CustomerCode:null,
      },
      storeEntityList: [], //门店列表
      treatDetailList: [], //消耗明细
      treatDetailSum: {},
      treatDetailLoading: false,

      treatRefundDetailList: [], //消耗退款明细
      treatRefundDetailSum: {},
      treatRefundDetailLoading: false,

      //需要给分页组件传的信息
      treatPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },

      //需要给分页组件传的信息
      treatRefundPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      refundTreatDetailExport:false,
      treatDetailExport:false,
    }
  },
  methods: {
      beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.refundTreatDetailExport = permission.permission(
          to.meta.Permission,
          "Report-Entity-RefundTreatDetail-Export"
        );
        vm.treatDetailExport = permission.permission(
          to.meta.Permission,
          "Report-Entity-TreatDetail-Export"
        );
      });
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    handleTreatSearch() {
      var that = this;
      that.treatPaginations.page = 1;
      that.treatSearch();
    },
    handleTreatDetailPageChange(page) {
      this.treatPaginations.page = page;
      this.treatSearch();
    },
    // 消耗搜索
    treatSearch() {
      var that = this;
      if (that.searchTreatData.QueryDate != null) {
        var params = {
          EntityID: that.searchTreatData.EntityID,
          StartDate: that.searchTreatData.QueryDate[0],
          // StartDate: "2021-03-02",
          EndDate: that.searchTreatData.QueryDate[1],
          TreatCardTypeName: that.searchTreatData.TreatCardTypeName.trim(),
          GoodName: that.searchTreatData.GoodName.trim(),
          IsLargess: that.searchTreatData.IsLargess,
          PageNum: that.treatPaginations.page,
          CustomerName:that.searchTreatData.CustomerName,
          CustomerCode:that.searchTreatData.CustomerCode,
        };
        that.treatDetailLoading = true;
        API.getTreatDetailStatement(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.treatDetailSum = res.Data.entityTreatDetailSumStatementForm;
              that.treatDetailList =
                res.Data.entityTreatDetailStatementForms.List;
              that.treatPaginations.total =
                res.Data.entityTreatDetailStatementForms.Total;
              that.treatPaginations.page_size =
                res.Data.entityTreatDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.treatDetailLoading = false;
          });
      }
    },
    getTreatDetailListSummaries(param) {
      // const { columns, data } = param;
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      sums[8] = "";
      sums[9] = "";
      sums[10] = "";
      sums[11] = "";
      if(!this.treatDetailSum){ sums[13] = "";sums[14] = "";  sums[15] = ""; return sums;}
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[12] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatDetailSum.TotalAmount)}
        </span>
      );
      sums[13] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatDetailSum.TreatPayAmount)}
        </span>
      );
      sums[14] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatDetailSum.TreatLargessAmount)}
        </span>
      );
      return sums;
    },

    handleTreatRefundSearch() {
      var that = this;
      that.treatRefundPaginations.page = 1;
      that.treatRefundSearch();
    },
    handleTreatRefundDetailPageChange(page) {
      this.treatRefundPaginations.page = page;
      this.treatRefundSearch();
    },
    // 退消耗搜索
    treatRefundSearch() {
      var that = this;
      if (that.searchTreatRefundData.QueryDate != null) {
        var params = {
          EntityID: that.searchTreatRefundData.EntityID,
          StartDate: that.searchTreatRefundData.QueryDate[0],
          // StartDate: "2021-03-02",
          EndDate: that.searchTreatRefundData.QueryDate[1],
          TreatCardTypeName: that.searchTreatRefundData.TreatCardTypeName.trim(),
          GoodName: that.searchTreatRefundData.GoodName.trim(),
          IsLargess: that.searchTreatRefundData.IsLargess,
          PageNum: that.treatRefundPaginations.page,
          CustomerName:that.searchTreatRefundData.CustomerName,
          CustomerCode:that.searchTreatRefundData.CustomerCode,
        };
        that.treatRefundDetailLoading = true;
        API.getTreatRefundDetailStatement(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.treatRefundDetailSum = res.Data.entityTreatRefundDetailSumStatementForm;
              that.treatRefundDetailList =
                res.Data.entityTreatRefundDetailStatementForms.List;
              that.treatRefundPaginations.total =
                res.Data.entityTreatRefundDetailStatementForms.Total;
              that.treatRefundPaginations.page_size =
                res.Data.entityTreatRefundDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.treatRefundDetailLoading = false;
          });
      }
    },
    getTreatRefundDetailListSummaries(param) {
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      sums[8] = "";
      sums[9] = "";
      sums[10] = "";
      if(!this.treatRefundDetailSum){ sums[11] = "";sums[12] = "";  sums[13] = ""; return sums;}
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[11] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatRefundDetailSum.TotalAmount)}
        </span>
      );
      sums[12] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatRefundDetailSum.TreatPayAmount)}
        </span>
      );
      sums[13] = (
        <span class="font_weight_600">
          {filter_NumFormat(this.treatRefundDetailSum.TreatLargessAmount)}
        </span>
      );
      return sums;
    },
    /** 数据导出 消耗明细 */
    downloadTreatExcel(){
      var that=this;
      if (that.searchTreatData.QueryDate != null) {
        var params = {
          EntityID: that.searchTreatData.EntityID,
          StartDate: that.searchTreatData.QueryDate[0],
          EndDate: that.searchTreatData.QueryDate[1],
          TreatCardTypeName: that.searchTreatData.TreatCardTypeName.trim(),
          GoodName: that.searchTreatData.GoodName.trim(),
          IsLargess: that.searchTreatData.IsLargess,
        };

        that.downloadLoading=true
        API.exportTreatDetailStatement(params)
        .then(res => {
            this.$message.success({
              message: '正在导出',
              duration: '4000'
            })
            const link = document.createElement("a");
            let blob = new Blob([res], {type: 'application/octet-stream'});
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = '消耗明细.xlsx'; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .finally(function () {
            that.downloadLoading = false;
        });
      }
    },
    
    /** 数据导出 退消耗明细 */
    downloadreatRefundExcel(){
      var that=this;
      if (that.searchTreatRefundData.QueryDate != null) {
        var params = {
          EntityID: that.searchTreatRefundData.EntityID,
          StartDate: that.searchTreatRefundData.QueryDate[0],
          EndDate: that.searchTreatRefundData.QueryDate[1],
          TreatCardTypeName: that.searchTreatRefundData.TreatCardTypeName.trim(),
          GoodName: that.searchTreatRefundData.GoodName.trim(),
          IsLargess: that.searchTreatRefundData.IsLargess,
        };

        that.downloadLoading=true
        API.exportTreatRefundDetailStatement(params)
        .then(res => {
            this.$message.success({
              message: '正在导出',
              duration: '4000'
            })
            const link = document.createElement("a");
            let blob = new Blob([res], {type: 'application/octet-stream'});
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = '退消耗明细.xlsx'; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .finally(function () {
            that.downloadLoading = false;
        });
      }
    },
  },
  mounted() {
    var that = this;
    that.refundTreatDetailExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Entity-RefundTreatDetail-Export"
    );
    that.treatDetailExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Entity-TreatDetail-Export"
    );
    that.getstoreEntityList();
    that.handleTreatSearch();
    that.handleTreatRefundSearch();
  },
}
</script>

<style lang="scss">
.ReportEntityTreatDetail {
  .el-tabs--border-card {
    border: 0px !important;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0px 0 rgba(0, 0, 0, 0);
  }
  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
</style>